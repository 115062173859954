import { FC } from 'react';
import { Col } from '../../../../Simple/Layout/Flex/Col/Col.com';
import { Icon } from '../../../../Simple/Icon/Icon.com';
import { requestPost } from '../../../../../Utils/Request';
import { API_URL } from '../../../../../Constants/Constants';

type Props = {
    value: string;
    downloadCallback: (name: string) => void;
};

// DownloadCellRenderer Component
export const DownloadCellRenderer: FC<Props> = (props: Props) => {
    const { value, downloadCallback } = props;

    // Download QrdaTemplate
    const downloadQrdaTemplates = async () => {
        try {
            const response = await fetch(`${API_URL}/qrda_templates/download`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    template_name: value,
                })
            });

            // Check Response
            if (response.status === 200) {
                const blob = await response.blob();
                let filename = `${value}.zip`;

                const contentDisposition = response.headers.get('Content-Disposition');
                if (contentDisposition && contentDisposition.includes('filename=')) {
                    filename = contentDisposition.split('filename=')[1].trim();
                }

                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                a.remove();
            } else {
                console.error('Error downloading template:', response.statusText);
            }
        } catch (error: any) {
            console.error('Error:', error?.message);
        }
    };

    return (
        <Col alignitems="center">
            <Icon size={18} name="download" onClick={downloadQrdaTemplates} />
        </Col>
    )
};