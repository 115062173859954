import React from 'react';
import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { UpdateSchemaProps } from '../../../Types/UpdateSchema.type';
import classNames from '../../../Utils/ClassNames';
import { ColDef, GridApi, SortChangedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { Pagination } from './Pagination/Pagination.com';
import { Col } from '../../Simple/Layout/Flex/Col/Col.com';
import { Row } from '../../Simple/Layout/Flex/Row/Row.com';
import { Modal } from '../../Simple/Modal/Modal.com';
import { Text } from '../../Simple/Text/Text.com';
import { Button } from '../../Simple/Button/Button.com';
import { FormData } from '../../Simple/Form/FormData.com';
import { TextCellEditor } from './CellEditors/TextCellEditor/TextCellEditor.com';
import { NumberCellEditor } from './CellEditors/NumberCellEditor/NumberCellEditor.com';
import { DropdownCellEditor } from './CellEditors/DropdownCellEditor/DropdownCellEditor.com';
import { HtmlCellEditor } from './CellEditors/HmtlCellEditor/HtmlCellEditor.com';
import { HtmlCellRenderer } from './CellRenderens/HtmlCellRenderer/HtmlCellRenderer.com';
import { TextCellRenderer } from './CellRenderens/TextCellRenderer/TextCellRenderer.com';
import { ListCellRenderer } from './CellRenderens/ListCellRenderer/ListCellRenderer.com';
import { getRenderer } from './CellRenderens/Renderers';
import { stringComparator } from './Comparator';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './DataTable.css';
import css from './DataTable.module.scss';

type Props = {
    theme?: 'light' | 'dark';
    headers: string[];
    rows?: any[];
    defaultColDef?: any;
    pageSizes?: number[];
    deleteAction?: boolean;
    editable?: boolean;
    onGridReady?: () => void;
    onAction?: (eventType: string, data: any) => void;
    primaryKey?: string;
    sortModel?: any[];
    columnModel?: any[];
    formData?: any[];
    formDataClassName?: string;
    formDataWidth?: string;
    formDataHeight?: string;
    floatingFilter?: boolean;
    sendAll?: boolean;
    handleSendAll?: (data: any[]) => void;
};

// DataTable Component
export const DataTable: FC<Props> = (props: Props) => {

    // Props
    const { headers = [], rows = [], defaultColDef = {flex: 1}, pageSizes = [10, 25, 50], onGridReady, onAction, theme = 'light' } = props;
    const { deleteAction = false, editable = false, primaryKey = '_id', columnModel = [], formData: formDataInitial = [], formDataClassName } = props;
    const { formDataWidth, formDataHeight, floatingFilter = true, sendAll = false, handleSendAll = (data: any[]) => {} } = props;

    // States
    const [colDefs, setColDefs] = useState<ColDef[]>([]);
    const [localRows, setLocalRows] = useState(rows);
    const [pageSize, setPageSize] = useState(10);
    const [deleteRowId, setDeleteRowId] = useState<string | null>(null);
    const [deleteConfirm, setDeleteConfirmation] = useState<boolean>(false);
    const [newRowModal, setNewRowModal] = useState<boolean>(false);
    const [formData, setFormData] = useState<any[]>(formDataInitial);
    const [initialized, setInitialized] = useState(false);
    const [loading, setLoading] = useState(true);

    // Refs
    const gridApiRef = React.useRef<GridApi | null>(null);

    // Update Local Rows
    useEffect(() => {
        initialized && setLoading(false);
        setInitialized(true);
        setLocalRows([...rows]);
    }, [rows]);

    // Normalize & Capitalize Column Name
    const normalizeColumnName = (name: string) => name.toLowerCase().replace(/_/g, ' ');
    const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

    // Handle Action
    const handleAction = (actionType: string, data: any) => {
        onAction && onAction(actionType, data);
    };

    // Get Filter Model
    const getFilterModel = () => {
        if (gridApiRef.current) {
            return gridApiRef.current.getFilterModel();
        }
        return null;
    };

    // On Form Data Clear
    const handleClearFormData = () => {
        setFormData((currentFormData) => {
            return currentFormData.map(item => {
                return { ...item, value: '' };
            });
        });
    };

    // On Form Data Changed
    const handleFormDataChange = (name: string | Record<any, any>, value: string) => {
        setFormData((currentFormData) => {
            return currentFormData.map(item => {
                if (item.name === name) {
                    return { ...item, value: value };
                }
                return item;
            });
        });
    };

    // On Form Data Submit
    const handleFormDataSubmit = () => {
        setNewRowModal(false);
        
        const newRowData = formData
            .filter((item: any) => item.hasOwnProperty('name'))
            .map((row: any) => ({name: row.name, value: row.value}));

        handleAction('add', newRowData);
        handleClearFormData();
    };

    // On Page Size Change
    const handlePageSizeChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const newPageSize = parseInt(event.target.value, 10);
        setPageSize(newPageSize);
    };

    // On Add New Row
    const onAddNewRow = () => {
        setNewRowModal(true);
    };

    // On Send All
    const onSendAll = () => {
        const filteredData: any[] = [];

        if (gridApiRef.current) {
            gridApiRef.current.forEachNodeAfterFilter(node => {
                filteredData.push(node.data);
            });
        }

        handleSendAll(filteredData);
    };

    // Handle Add New Row Modal Close
    const handleAddNewRowModalClose = () => {
        setNewRowModal(false);
        handleClearFormData();
    };

    // Handle Add New Row Modal Apply
    const handleAddNewRowModalApply = () => {
        setNewRowModal(false);
    };

    // Handle Delete Row
    const handleDeleteRow = (row: any) => {
        
        // Show Delete Confirmation
        setDeleteConfirmation(true);

        // Set Delete Row Id
        setDeleteRowId(row[primaryKey]);
    };

    // Handle Delete Confirmation Close
    const handleDeleteConfirmationClose = () => {

        // Hide Delete Confirmation
        setDeleteConfirmation(false);

        // Reset Delete Row Id
        setDeleteRowId(null);
    };

    // Handle Delete Confirmation Apply
    const handleDeleteConfirmationApply = () => {

        // Hide Delete Confirmation
        setDeleteConfirmation(false);

        // Delete Row
        setLocalRows(row => row.filter(row => row[primaryKey] !== deleteRowId));
        setDeleteRowId(null);
        handleAction('delete', deleteRowId);
    };

    // On Filter Changed
    const handleFilterChanged = () => {
        if (gridApiRef.current) {
            const filterModel = getFilterModel();
            // gridApiRef.current.showLoadingOverlay();
            handleAction('filterChanged', filterModel);
        }
    };

    // On Sort Changed
    const handleSortChanged = (e: SortChangedEvent) => {
        const sortState = e.columnApi.getColumnState()
        .filter((s) => s.sort != null)
        .map((s) => ({ colId: s.colId, sort: s.sort, sortIndex: s.sortIndex }));

        // if (gridApi) {
        //     gridApi.showLoadingOverlay();
        //     handleAction('sortChanged', sortState);
        // }
    };

    // On Pagination Changed
    // const handlePaginationChanged = () => {
    //     if (gridApi) {
    //         gridApi.showLoadingOverlay();
    //         handleAction('paginationChanged', {
    //             pageNumber: gridApi.paginationGetCurrentPage(),
    //             pageSize: gridApi.paginationGetPageSize(),
    //         });
    //     }
    // };

    // On Cell Value Changed
    const handleCellValueChanged = (params: any) => {
        const updatedRowData = { ...params.data, [params.colDef.field]: params.newValue };
        
        setLocalRows(prevRows => prevRows.map(row => row[primaryKey] === updatedRowData[primaryKey] ? updatedRowData : row));
        handleAction('update', { 
            data: {
                [primaryKey]: updatedRowData[primaryKey],
                [params.colDef.projectField || params.colDef.field]: params.newValue
            },
            schema: {
                primaryKey: primaryKey,
                fields: [params.colDef.projectField || params.colDef.field]
            }
        } as UpdateSchemaProps);
    };

    // Init Column Defs
    const initColDefs = () => {
        
        // Columns Definitions
        const columnDefs: any[] = headers.map(header => ({ 
            headerName: capitalize(normalizeColumnName(header)), 
            autoHeight: true,
            // cellStyle: {textAlign: 'center'},
            // rowHeight: 100,
            // wrapText: true,
            field: header,
            sortable: true,
            filter: true,
            floatingFilter: floatingFilter,
            // suppressMenu: true,
            filterParams: {
                // suppressAndOrCondition: true,
                // filterOptions: []
                // filterOptions: ['contains', 'notContains', 'startsWith', 'endsWith', 'equals', 'notEqual', 'greaterThan', 'lessThan', 'inRange'],
            },
            resizable: true,
            editable: editable, //header !== primaryKey,
            cellDataType: false,
            hide: header === primaryKey,
            stringComparator
            // pinned: header === 'event' ? 'left' : undefined,
        }));

        // Add Delete Action Column
        if (deleteAction === true) {
            columnDefs.push({
                headerName: 'Actions',
                field: 'actions',
                cellRenderer: (params: any) => (
                    <Col style={{minHeight: '100%', }} justifycontent="center">
                        <Button onClick={() => handleDeleteRow(params.data)}>
                            <Text size="0" color='inherit'>Delete</Text>
                        </Button>
                    </Col>
                ),
                minWidth: 90,
                maxWidth: 90,
            });
        }

        // Apply Custom Column Model
        const mergedColumnDefs: ColDef[] = columnDefs.map((colDef: ColDef): ColDef => {
            const modelConfig = columnModel.find(m => m.field === colDef.field);
            const triangle = 'url("data:image/svg+xml,<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M11.646 15.146 5.854 9.354a.5.5 0 0 1 .353-.854h11.586a.5.5 0 0 1 .353.854l-5.793 5.792a.5.5 0 0 1-.707 0Z"></path></svg>")';
            
            // Custom Model Config
            if (modelConfig) {
                const customModelConfig = { 
                    ...colDef, 
                    ...modelConfig,
                    cellClass: modelConfig.editable ? css.EditableCell : undefined,
                    // cellStyle: modelConfig.editable ? { backgroundImage: triangle } : undefined,
                    // cellDataType: modelConfig.type || 'text'
                };

                // Add editors
                if (modelConfig.editable === true) {

                    // Text
                    if (modelConfig.editorType === 'text' || modelConfig.type === undefined) {
                        customModelConfig['cellEditor'] = TextCellEditor;
                    }

                    // Number
                    if (modelConfig.editorType === 'number') {
                        customModelConfig['cellEditor'] = NumberCellEditor;
                    }

                    // Select
                    if (modelConfig.editorType === 'select' || modelConfig.editorType === 'dropdown') {
                        customModelConfig['cellEditor'] = DropdownCellEditor;
                    }

                    // HTML Editor
                    if (modelConfig.editorType === 'html') {
                        customModelConfig['cellEditor'] = HtmlCellEditor;
                        customModelConfig['cellEditorPopup'] = true;
                    }
                }

                // Add renderers
                const renderer = modelConfig.renderer;
                if (renderer) {

                    // Dynamic Renderer
                    if (Array.isArray(renderer)) {

                        // Renderers
                        customModelConfig['cellRenderer'] = (props: any) => renderer.map((r: any, index: number) => {
                            
                            // Rendered defined as string - with no props
                            if (typeof r === 'string') {
                                return <React.Fragment key={index}>{getRenderer(r, props)}</React.Fragment>;
                            }

                            // Rendered defined as object - with props
                            if (typeof r === 'object' && r !== null) {
                                
                                // Options
                                if (r.options) {
                                    // useHandleAction option
                                    if (r.options.useHandleAction) {
                                        !r.props && (r.props = {});
                                        r.props.handleAction = handleAction;
                                    }
                                }
                                return <React.Fragment key={index}>{getRenderer(r.name, {...props, ...r.props})}</React.Fragment>;
                            }
                        });
                    }

                    // Text
                    else if (renderer === 'text') {
                        customModelConfig['cellRenderer'] = TextCellRenderer;
                    } 

                    // List
                    else if (renderer === 'list') {
                        customModelConfig['cellRenderer'] = ListCellRenderer;
                    } 

                    // Html
                    else if (renderer === 'html') {
                        customModelConfig['cellRenderer'] = HtmlCellRenderer;
                    }

                    // Function
                    else if (typeof renderer === 'function') {
                        customModelConfig['cellRenderer'] = renderer;
                    }

                    else {
                        customModelConfig['cellRenderer'] = TextCellRenderer;
                    }
                } else {
                    customModelConfig['cellRenderer'] = TextCellRenderer;
                }
                
                return customModelConfig;
            }

            return colDef;
        });

        setColDefs(mergedColumnDefs);
    };

    // On Grid Ready
    const handleGridReady = useCallback((params: any) => {
        // console.log('API', params);
        // setGridApi(params.api);
        gridApiRef.current = params.api;
        onGridReady && onGridReady();
    }, []);

    // On rows updated
    useEffect(() => {
        initColDefs();
    }, [rows]);
    
    return (
        <Col grow="1" className={classNames(css.DataTable, theme === 'dark' ? 'ag-theme-alpine-dark' : 'ag-theme-alpine')}>
            
            {/* Page Size Selector */}
            <Row justifycontent="end" paddingBottom="0.5">
            
                {/* Send All Button */}
                {
                    sendAll === false ? null :
                    <Row paddingRight="0.5">
                        <Button onClick={onSendAll}>Send All</Button>
                    </Row>
                }
                
                {/* Add new Row Button */}
                {
                    formData.length === 0 ? null :
                    <Row paddingRight="0.5">
                        <Button onClick={onAddNewRow}>Add new row</Button>
                    </Row>
                }

                {/* Page Sizes */}
                {/* <Row>
                    <select onChange={handlePageSizeChange} value={pageSize}>
                        {
                            pageSizes.map((size: number, index: number) => <option key={index} value={size}>{size}</option>)
                        }
                    </select>
                </Row> */}
            </Row>

            {/* Grid */}
            <AgGridReact 
                className={css.DataTable}
                onGridReady={handleGridReady}
                columnDefs={colDefs}
                rowData={localRows}
                defaultColDef={defaultColDef}
                onFilterModified={handleFilterChanged}
                onSortChanged={handleSortChanged}
                // onPaginationChanged={handlePaginationChanged}
                onCellValueChanged={handleCellValueChanged}
                pagination={true}
                paginationPageSize={pageSize}
                loadingOverlayComponent={'Loading...'}
                localeText={{
                    noRowsToShow: loading ? 'Loading...' : 'No rows to show'
                }}
            />

            {/* Pagination */}
            {/* <Pagination /> */}

            {/* Add new Row */}
            <Modal width={formDataWidth} height={formDataHeight} show={newRowModal} title="Add new row" hideFooter={true} onCancel={handleAddNewRowModalClose} onApply={handleAddNewRowModalApply}>
                <FormData className={formDataClassName} config={formData} onChange={handleFormDataChange} onSubmit={handleFormDataSubmit} />
            </Modal>

            {/* Delete Confirmation */}
            <Modal show={deleteConfirm} title="Confirm" onCancel={handleDeleteConfirmationClose} onApply={handleDeleteConfirmationApply}>
                <Row>
                    <Text>Please confirm you want to delete this row.</Text>
                </Row>
            </Modal>
        </Col>
    )
};