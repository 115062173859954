// Number Comparator
export const numberComparator = (valueA: string | number | null | undefined, valueB: string | number | null | undefined, nodeA: any, nodeB: any, isInverted: boolean): number => {
    const parseNumber = (value: string | number | null | undefined): number => {
        if (typeof value === 'number') return value; // Return the value as is if it's already a number
        if (!value) return NaN; // Return NaN for null, undefined, or empty string
        const parsed = parseFloat(value.replace(/,/g, '')); // Remove commas and parse the string as a number
        return isNaN(parsed) ? NaN : parsed; // Return the parsed number or NaN if parsing fails
    };

    const numberA = parseNumber(valueA);
    const numberB = parseNumber(valueB);

    if (isNaN(numberA) && isNaN(numberB)) return 0; // Both values are invalid (NaN), treat them as equal
    if (isNaN(numberA)) return isInverted ? 1 : -1; // First value is invalid, place it lower or higher based on sort order
    if (isNaN(numberB)) return isInverted ? -1 : 1; // Second value is invalid, place it lower or higher based on sort order

    return isInverted ? numberB - numberA : numberA - numberB; // Compare numbers based on the sorting direction
};

export const comparator = numberComparator;

// String Comparator
export const stringComparator = (valueA: string | null | undefined, valueB: string | null | undefined, nodeA: any, nodeB: any, isInverted: boolean): number => {
    const safeValueA = valueA || '';
    const safeValueB = valueB || '';

    if (safeValueA < safeValueB) {
        return isInverted ? 1 : -1;
    }
    if (safeValueA > safeValueB) {
        return isInverted ? -1 : 1;
    }

    return 0;
};

// Monetary comparator
export const monetaryComparator = (valueA: string, valueB: string) => {
    const numberA = parseFloat(valueA.replace(/[$,]/g, ''));
    const numberB = parseFloat(valueB.replace(/[$,]/g, ''));

    return numberA - numberB;
};